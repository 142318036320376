<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Filtrar por </strong>
      </CCardHeader>
      <CCardBody>
        <!-- Para buscar por proyecto-->
        <CRow>
          <CCol sm="3">
            <CInput
              label="Documento del provedor"
              placeholder="12345678"
              v-model="filter.document"
            />
          </CCol>
          <CCol sm="3">
            <CInput
              type="date"
              label="Fecha Inicio"
              v-model="filter.date_initial"
            >
            </CInput>
          </CCol>
          <CCol sm="3">
            <CInput
              type="date"
              label="Fecha Fin"
              v-model="filter.date_end"
              :min="filter.date_initial"
            >
            </CInput>
          </CCol>
          <CCol sm="2">
            <label>&nbsp;</label>
            <br />
            <CButton type="submit" color="primary" @click="mtd_search_filter()">
              <i class="fa fa-search"></i> Buscar Acopios
            </CButton>
          </CCol>
          <CCol sm="2"> &nbsp; </CCol>
          <!-- <CCol sm="2"><br>
                  <CButton type="submit" color="success" @click="mtd_export_pdf()">
                      <i class="fa fa-file-pdf"></i> Reporte PDF
                  </CButton>
              </CCol> -->
          <!-- <CCol sm="2"><br>
                  <CButton type="submit" color="success" @click="mtd_export_excel()" v-if="load.excel == true">
                      <i class="fa fa-file-excel"></i> Reporte EXCEL
                  </CButton>
              </CCol> -->
        </CRow>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de Acopios"
          icon="fas fa-dolly"
          btn_name="acopio"
          :size="'lg'"
          :button_new="false"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="false"
          :buttonDelete="false"
          :myButtons="myButtons"
          @mtd_view_pdf="mtd_view_pdf"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
const fields = [
  { key: "id", label: "Id", _style: "width:3%" },
  { key: "peso_bruto", label: "Peso Bruto", _style: "width:12%;" },
  { key: "tara_saco", label: "Tara Saco", _style: "width:12%;" },
  { key: "peso_neto", label: "Peso Neto", _style: "width:12%;" },
  { key: "neto_num_qq", label: "Neto Num QQ", _style: "width:12%;" },
  { key: "precio_kg", label: "Precio XKG", _style: "width:12%;" },
  { key: "detail", label: "Detalle", _style: "width:37%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: { CTableWrapper },
  data() {
    return {
      prefix: "acopio",
      fields,
      data: [],
      filter: {
        date_initial: "",
        date_end: "",
      },
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Ticket PDF",
          action: "mtd_view_pdf",
          icon: "fas fa-file-alt",
        },
      ],
      load: {
        excel: false,
      },
    };
  },
  created() {
    let actual = new Date();
    let fecha;
    fecha =
      actual.getFullYear() +
      "-" +
      ("0" + (actual.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + actual.getDate()).slice(-2);
    this.filter.date_initial = fecha;
    this.filter.date_end = fecha;
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_search_filter: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/searchFilter",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
            if (response.state == 0) {
                this.data = response.data.map((item, order) => {
                    return { ...item, order };
                });

                if (this.data.length >= 1) {
                    this.load.excel = true;
                } else {
                    this.load.excel = false;
                }
            } else {
                bus.$emit("alert", {
                    color: "danger",
                    message: "EL DOCUMENTO NO EXISTE",
                });
            }    
                
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_export_excel: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          "GenerateEXCEL",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          this.download(response);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    // descarga el archivo
    download: function (data) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "acopio.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    mtd_export_pdf: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/GeneratePDF",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          window.open("/assets/sales/" + response + ".pdf", "_blank");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    mtd_view_pdf: function (item) {
        window.open("/assets/acopio/" + item.uuid + ".pdf", "_blank");
    },
  },
};
</script>